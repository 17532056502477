import { stages } from '../../../back-end/deploy/environments';

/**
 * The stage to use for API (and websocket) requests.
 */
const STAGE = 'prod';

/**
 * Variables to configure an ITER IDEA's cloud app, together with its inner modules.
 */
export const environment = {
  idea: {
    project: 'iter-idea-link',
    ionicExtraModules: ['common', 'auth'],
    app: { version: '0.0.1', bundle: 'com.iteridea.link' },
    api: { url: stages[STAGE].apiDomain },
    auth: {
      title: 'URL shortener',
      website: 'https://iter-idea.com',
      hasIntroPage: false,
      registrationIsPossible: false,
      singleSimultaneousSession: false,
      forceLoginWithMFA: false,
      passwordPolicy: {
        minLength: 8,
        requireLowercase: true,
        requireDigits: true,
        requireSymbols: true,
        requireUppercase: true
      }
    }
  },
  aws: {
    cognito: { userPoolId: 'eu-south-1_iQExw40Om', userPoolClientId: '30tm2e7fq0v2c1aic1jvjkmb3b' }
  }
};
