/**
 * The codename of the project.
 */
export const PROJECT = 'iter-idea-link';
/**
 * The purchased domain to use.
 */
export const DOMAIN = 'iter-idea.link';

export const parameters: Parameters = {
  project: PROJECT,
  mediaDomain: 'media.'.concat(DOMAIN),
  firstAdminEmail: 'matteo.carbone@iter-idea.com'
};

export const stages: { [stage: string]: Stage } = {
  prod: {
    domain: 'app.'.concat(DOMAIN),
    apiDomain: DOMAIN,
    destroyDataOnDelete: false,
    logLevel: 'INFO'
  },
  dev: {
    domain: 'dev.'.concat(DOMAIN),
    apiDomain: 'dev-api.'.concat(DOMAIN),
    destroyDataOnDelete: true,
    logLevel: 'DEBUG'
  }
};

export interface Parameters {
  /**
   * Project key (unique to the AWS account).
   */
  project: string;
  /**
   * The domain name where to reach the front-end's media files.
   */
  mediaDomain: string;
  /**
   * The email address of the first (admin) user.
   */
  firstAdminEmail: string;
}

export interface Stage {
  /**
   * The domain name where to reach the front-end.
   */
  domain: string;
  /**
   * The domain for the API.
   */
  apiDomain: string;
  /**
   * Whether to delete the data when the environment is deleted.
   * It should be True for dev and False for prod environments.
   */
  destroyDataOnDelete: boolean;
  /**
   * The minimum level of log to print in functions (default: `INFO`).
   */
  logLevel?: 'TRACE' | 'DEBUG' | 'INFO' | 'WARN' | 'ERROR' | 'FATAL';
}
